import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[9];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  When we first heard about this unique flavor of Thai tacos,
                  admittedly we were skeptical, but after one visit we were
                  sold. Thai Fresh blends the perfect amount of flavors, and we
                  plan to try every taco on the menu. We first tried the spicy
                  shrimp omelet taco, which is a jaw-dropping combination of
                  thai chilies, mushroom, basil, and soy sauce. After that we
                  took a deep breathe and moved to the pork belly and eggs
                  tacos, which is crispy pork belly, bell peppers, garlic, and
                  pickled shallots.
                </p>
                <p>
                  Thai Fresh has some very unique offerings as well. They offer
                  private cooking classes in home for authentic Thai meals,
                  which also is offered as a cooking party package. These same
                  classes are also offered virtually, depending on availability.
                  They also offer a cookbook, that contains many recipes from
                  the owner's family combination.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
